import React from "react"
import { TPoolRouteProps } from "../../../../routes.d"
import LoadingView from "../../../components/LoadingView"
import MultipleEntriesLobby from "../components/MultipleEntriesLobby"

function Games(props: TPoolRouteProps) {
  const { poolData } = props
  const { allSegments } = poolData
  if (!allSegments.length) {
    return <LoadingView className="lobby" />
  }
  return <MultipleEntriesLobby {...props} />
}

export default Games
